
.container {
    /*width: 100%;*/
    /*height: 100vh;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*!*border: 1px solid red;*!*/
    /*!*background-image: url("./../../assets/shool.png");*!*/
    /*background-image: url("https://img5.goodfon.ru/original/1920x1080/4/9e/library-dublin-ireland.jpg");*/
    /*background-repeat: no-repeat;*/
    /*background-size: contain;*/
    /*padding-top: 300px;*/
}

/*.content {*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    background-color: white;*/
/*}*/

/*.schoolImg {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/