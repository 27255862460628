.footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f8f8f8;
    padding: 20px 0;
    border: 1px solid blue;
  }
  
  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }